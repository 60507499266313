<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :to="{name:'pm-category-banners', params:{id:item.id}}"
        icon
      >
        <v-icon class="mx-2">fal fa-images</v-icon>
      </v-btn>
    </template>
    <span>Administreaza Bannerele</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    item: {
      required: true
    }
  }
}
</script>
