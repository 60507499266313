<template>
  <v-select
    v-model="selectedOption"
    :items="filters"
    :loading="loading"
    :search-input.sync="search"
    class="filter-select text-sm"
    dense
    hide-details
    hide-no-data
    item-text="name"
    item-value="id"
    label="3rd Level menu"
    no-filter
    outlined
  ></v-select>
</template>

<script>
export default {
  name: 'MenuCustomSubListingSelect',
  props: {
    value: {},
    productCategory: {
      required: true
    }
  },
  data () {
    return {
      filters: [],
      search: '',
      loading: false
    }
  },
  computed: {

    selectedOption: {
      get () {
        return this.value
      },
      set (val) {
        if (typeof val === 'undefined') {
          this.$emit('input', '')
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    },
    value (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      if (typeof this.productCategory === 'undefined') {
        console.warn('Product category not yed defined')
        this.loading = false
      } else {
        search = search || ''
        this.$http.get(`/product-categories/${this.productCategory}/filters/current-or-parent?filter[name]=${search}`)
          .then(({data}) => {
            this.filters = data
            this.loading = false
          })
      }
    }
  },
  mounted () {
    this.loadItems()
  }
}
</script>
