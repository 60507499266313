<template>
  <action-edit
    :action-url="`product-categories/${item.id}`"
    :item="item"
    button-label="Editeaza Categoria"
  >

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field :value="form.parent ? form.parent.name : '-'" dense disabled hide-details
                      label="Categorie parinte" outlined/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details label="Nume" outlined/>
        <error-messages :errors="errors.name"></error-messages>
      </div>

      <div class="mb-4">
        <v-textarea v-model="form.short_description" dense hide-details label="Descriere scurta" outlined/>
        <error-messages :errors="errors.short_description"></error-messages>
      </div>

      <div v-if="!form.parent_category_id" class="mb-4">
        <div v-if="form.icon">
          <v-img
            :src="form.icon"
            class="mb-4"
            contain
            max-height="150"
            max-width="500"
          />
        </div>
        <base64-file-uploader
          ref="avatar"
          v-model="form.icon_file"
          label="Iconita"
        />
      </div>

      <div v-if="form.parent_category_id" class="mb-4">
        <product-quality-selector v-model="form.qualities_ids" label="Alege calitatiile disponibile" multiple/>
        <error-messages :errors="errors.qualities_ids"></error-messages>
      </div>

      <div class="mb-4">
        <badge-selector v-model="form.badges_ids" label="Badge-uri"/>
        <error-messages :errors="errors.badges_ids"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.green_tax" dense hide-details label="Taxa verde" outlined type="numeric"/>
        <error-messages :errors="errors.green_tax"></error-messages>
      </div>

      <div class="mb-4">
        <span class="font-weight-black">SEO:</span>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.seo_meta_title" dense hide-details label="Meta Title" outlined/>
        <error-messages :errors="errors.seo_meta_title"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.seo_meta_description" dense hide-details label="Meta Description" outlined/>
        <error-messages :errors="errors.seo_meta_description"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.seo_meta_keywords" dense hide-details label="Meta Keywords" outlined/>
        <error-messages :errors="errors.seo_meta_keywords"></error-messages>
      </div>

      <div v-if="form.parent_category_id">
        <span class="font-weight-black">Optiuni Meniu:</span>
      </div>
      <div v-if="form.parent_category_id">
        <menu-custom-sub-listing-select v-model="form.menu_filter_id_as_child_listing" :product-category="form.id"/>
        <error-messages :errors="errors.menu_filter_id_as_child_listing"></error-messages>
      </div>

    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'

import ProductCategorySelector from '@/components/autocompletes/ProductCategorySelector'
import ProductQualitySelector from '@/components/autocompletes/ProductQualitySelector'
import BadgeSelector from '@/components/autocompletes/BadgeSelector'
import MenuCustomSubListingSelect from '@/views/ProductManagement/Categories/components/MenuCustomSubListingSelect'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'

export default {
  components: {
    ActionEdit,
    Base64FileUploader,
    MenuCustomSubListingSelect,
    BadgeSelector,
    ProductQualitySelector,
    ProductCategorySelector,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}

</script>
