<template>
  <action-add-new action-url="product-categories" button-label="Adauga Categorie noua">

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <product-category-selector v-model="form.parent_category_id" label="Categorie parinte"/>
        <error-messages :errors="errors.parent_category_id"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details label="Nume" outlined/>
        <error-messages :errors="errors.name"></error-messages>
      </div>

      <div class="mb-4">
        <v-textarea v-model="form.short_description" dense hide-details label="Descriere scurta" outlined/>
        <error-messages :errors="errors.short_description"></error-messages>
      </div>

      <div class="mb-4" v-if="!form.parent_category_id">
        <div v-if="form.icon">
          <v-img
            :src="form.icon"
            class="mb-4"
            contain
            max-height="150"
            max-width="500"
          />
        </div>
        <base64-file-uploader
          ref="avatar"
          v-model="form.icon_file"
          label="Iconita"
        />
      </div>

      <div v-if="form.parent_category_id" class="mb-4">
        <product-quality-selector v-model="form.qualities" label="Alege calitatiile disponibile" multiple/>
        <error-messages :errors="errors.qualities"></error-messages>
      </div>

      <div class="mb-4">
        <badge-selector v-model="form.badges" label="Badge-uri"/>
        <error-messages :errors="errors.badges"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.green_tax" dense hide-details label="Taxa verde" outlined type="numeric"/>
        <error-messages :errors="errors.green_tax"></error-messages>
      </div>

      <div class="mb-4">
        <span class="font-weight-black">SEO:</span>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.seo_meta_title" dense hide-details label="Meta Title" outlined/>
        <error-messages :errors="errors.seo_meta_title"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.seo_meta_description" dense hide-details label="Meta Description" outlined/>
        <error-messages :errors="errors.seo_meta_description"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.seo_meta_keywords" dense hide-details label="Meta Keywords" outlined/>
        <error-messages :errors="errors.seo_meta_keywords"></error-messages>
      </div>

    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ProductCategorySelector from '@/components/autocompletes/ProductCategorySelector'
import ProductQualitySelector from '@/components/autocompletes/ProductQualitySelector'
import BadgeSelector from '@/components/autocompletes/BadgeSelector'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'

export default {
  components: {
    Base64FileUploader,
    BadgeSelector,
    ProductQualitySelector,
    ProductCategorySelector,
    ErrorMessages,
    ActionAddNew
  }
}
</script>
